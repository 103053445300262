/* * *****************************************************************************
 *                JQUERY CODE FOR CUSTOM SELECT BOX 
 * ******************************************************************************
 *      Author:     Nitesh Kumar Verma
 *      Email:      nitesh.verma@edynamic.net
 *      Website:    http://www.edynamic.net
 * 		Date Created 10 Oct 2014
 *      File:       edyCustomSelectBox-1.3.js
 *      Version:    1.3
 * ******************************************************************************
 *  VERION HISTORY:
 *                  NONE
 *
 * ******************************************************************************
 *  DESCRIPTION:
 *      This is a complete core jQuery code for Custom Select Box
 * ******************************************************************************
 * EXAMPLE USAGE
 *		$('#ID').edyCustomSelectBox();
 *		$('.class').edyCustomSelectBox();
 *      $('select').edyCustomSelectBox();
 *		
 * ******************************************************************************
 * Fix LOG
 *		Fix tabindex problem
 *		Fix default value problem
 * 
 * *******************************************************************************/
jQuery.fn.edyCustomSelectBox = function (options) {
    var defaults = {};
    var settings = $.extend({}, defaults, options);
    var keyinterval;
    var currindex = -1;
    //var zindexno=999;
    $(this).each(function (i) {
        if ($(this).hasClass("ignore")) {
            return;
        }
        var selid = $(this).attr("id");

        var tabindex = ($(this).attr("tabindex") != undefined) ? $(this).attr("tabindex") : 0;
        $(this).find("option:selected").prop("selected", true);
        var defaultValue = $(this).find("option:selected").text();
        if ($(this).find("option:selected").length == 0) {
            defaultValue = $(this).find("option").eq(0).text();
            $(this).find("option").eq(0).prop("selected", true);
        }

        var ultext = '<div class="stylish-select select" id="ul' + selid + '">';
        ultext += '<div class="stylish-select-left" tabindex="' + tabindex + '" data-filter=""><div class="stylish-select-right styledSelect">' + defaultValue + '</div></div>';
        ultext += '<ul class="listing">';
        $(this).find("option").each(function () {
            if ($(this).prop("selected")) {
                ultext += "<li data='" + $(this).val() + "' tabindex='0' class='selected'>" + $(this).text() + "</li>";
            } else {
                ultext += "<li data='" + $(this).val() + "' tabindex='0'>" + $(this).text() + "</li>";
            }
        });
        ultext += '</ul>';
        ultext += '</div>';

        $(this).hide();
        $(this).wrap('<div class="main">');
        $(this).after(ultext);
        //zindexno--;
    });

    $(document).on("click", ".stylish-select li", function (e) {

        $(this).parent().find("li.selected").removeClass("selected");
        $(this).focus().addClass("selected")
        if ($(this).parent().find("li.selected").index() == 0) {
            $(this).parents(".stylish-select").find(".stylish-select-right").removeClass('black').addClass('grey');
        } else {
            $(this).parents(".stylish-select").find(".stylish-select-right").removeClass('grey').addClass('black');
        }
        $(this).parents(".stylish-select").find(".stylish-select-right").text($(this).text());
        $(this).parents(".stylish-select").prev().val($(this).attr("data"));
        $(".stylish-select-left").removeClass('active');
        $(this).parents(".stylish-select").find(".stylish-select-left").focus();
        e.preventDefault();
        $(this).parent().hide();
        $(this).parents(".stylish-select").prev().trigger("change");
    });

    $(document).on("click", ".stylish-select-left", function (e) {
        $(".stylish-select-left").not(this).parents(".stylish-select").find(".listing").hide();
        e.stopPropagation();
        $(this).next().toggle();
        if ($(this).next().is(":visible")) {
            $(this).next().find("li.selected").focus();
        } else {
            $(this).focus();
        }

    });
    $(document).on("focusout", ".stylish-select-left", function (e) {
        $(this).parents(".stylish-select").prev().trigger("change");
    });



    $(document).on("keydown", ".stylish-select-left", function (e) {

        if (e.keyCode == "13") {
            $(this).next().show();
            $(this).next().find("li.selected").focus();
            e.preventDefault();

        } else if (e.keyCode == "38") {
            $(this).focus();
            if ($(this).next().find("li.selected").prev().length > 0) {

                $(this).next().find("li.selected").removeClass("selected").prev().focus().addClass("selected");
                if ($(this).next().find("li.selected").index() == 0) {
                    $(this).find(".stylish-select-right").removeClass('black').addClass('grey').text($(this).next().find("li.selected").text());
                } else {
                    $(this).find(".stylish-select-right").removeClass('grey').addClass('black').text($(this).next().find("li.selected").text());
                }
                $(this).parents(".stylish-select").prev().val($(this).next().find("li.selected").attr("data"));
            }
            e.preventDefault();
        } else if (e.keyCode == "40") {
            $(this).focus();
            if ($(this).next().find("li.selected").next().length > 0) {
                $(this).next().find("li.selected").removeClass("selected").next().focus().addClass("selected");
                if ($(this).next().find("li.selected").index() == 0) {
                    $(this).find(".stylish-select-right").removeClass('black').addClass('grey').text($(this).next().find("li.selected").text());
                } else {
                    $(this).find(".stylish-select-right").removeClass('grey').addClass('black').text($(this).next().find("li.selected").text());
                }
                //$(this).find(".stylish-select-right").text($(this).next().find("li.selected").text());
                $(this).parents(".stylish-select").prev().val($(this).next().find("li.selected").attr("data"));
            }
            e.preventDefault();

        } else if (e.keyCode > 47 && e.keyCode < 91) {
            clearInterval(keyinterval);
            if ($(this).attr("data-filter") != String.fromCharCode(e.which))
                $(this).attr("data-filter", $(this).attr("data-filter") + "" + String.fromCharCode(e.which));

            var currentval = $(this).find(".stylish-select-right").text();
            if (currentval == "") currentval = "-1";
            if ($(this).next().find("li:not('.selected'):containsIN('" + $(this).attr("data-filter") + "')").length > 0) {
                $(this).next().find("li").removeClass("selected");
                var nextli = $(this).next().find("li:containsIN('" + $(this).attr("data-filter") + "')");
                currindex++;
                if (currindex > nextli.length - 1) {
                    currindex = -1;
                }
                nextli.eq(currindex).addClass("selected");

                $(this).find(".stylish-select-right").text($(this).next().find("li.selected").text());
                $(this).parents(".stylish-select").prev().val($(this).next().find("li.selected").attr("data"));
            }

            keyinterval = setInterval(function () { clearDataFilter(); }, 300);
        } else if (e.keyCode == 16 || e.keyCode == 9 || e.keyCode == 27) {

        } else {
            e.preventDefault();
        }

    });

    $.extend($.expr[':'], {
        focusable: function (element) {
            var nodeName = element.nodeName.toLowerCase(),
                tabIndex = $.attr(element, 'tabindex');
            return (/input|select|textarea|button|object/.test(nodeName)
                ? !element.disabled
                : 'a' == nodeName || 'area' == nodeName
                    ? element.href || !isNaN(tabIndex)
                    : !isNaN(tabIndex))
                // the element and all of its ancestors must be visible
                // the browser may report that the area is hidden
                && !$(element)['area' == nodeName ? 'parents' : 'closest'](':hidden').length;
        }
    });
    var focusables = $(":focusable");


    $(document).on("keydown", ".stylish-select li", function (e) {
        if (e.keyCode == "13") {
            $(this).parents(".stylish-select").find(".stylish-select-left").focus();
        }
        if (e.keyCode == "13" || e.keyCode == 9) {
            $(this).parents(".stylish-select").find(".stylish-select-right").text($(this).text());
            $(this).parents(".stylish-select").prev().val($(this).attr("data"));
            $(".stylish-select-left").removeClass('active');
            $(this).parents(".stylish-select").find(".stylish-select-left").focusout();
            e.preventDefault();
            $(this).parent().hide();
        }
        else if (e.keyCode == "38") {
            $(this).focus();
            if ($('.stylish-select li:first-child').hasClass('focus')) {
                var currLi = $(this);
                setTimeout(function () {
                    currLi.addClass('selected');
                }, 100);
            }


            if ($(this).removeClass("selected").prev().length > 0) {
                $(this).removeClass("selected").prev().focus().addClass("selected");
                $(this).parents(".stylish-select").find(".stylish-select-right").text($(this).prev().text());
                $(this).parents(".stylish-select").prev().val($(this).prev().attr("data"));
            }

            e.preventDefault();
        }
        else if (e.keyCode == "40") {

            $(this).focus();
            if ($('.stylish-select li:last-child').hasClass('focus')) {
                var currLi = $(this);
                setTimeout(function () {
                    currLi.addClass('selected');
                }, 100);
            }


            if ($(this).removeClass("selected").next().length > 0) {
                $(this).removeClass("selected").next().focus().addClass("selected");
                $(this).parents(".stylish-select").find(".stylish-select-right").text($(this).next().text());
                $(this).parents(".stylish-select").prev().val($(this).next().attr("data"));
            }
            e.preventDefault();
        } else if (e.keyCode > 47 && e.keyCode < 91) {
            clearInterval(keyinterval);

            if ($(this).parents(".stylish-select").find(".stylish-select-left").attr("data-filter") != String.fromCharCode(e.which)) {
                $(this).parents(".stylish-select").find(".stylish-select-left").attr("data-filter", $(this).parents(".stylish-select").find(".stylish-select-left").attr("data-filter") + "" + String.fromCharCode(e.which));
            }
            var currentval = $(this).parents(".stylish-select").find(".stylish-select-right").text();
            if (currentval == "") currentval = "-1";

            if ($(this).parents(".stylish-select").find(".stylish-select-left").next().find("li:not('.selected'):containsIN('" + $(this).parents(".stylish-select").find(".stylish-select-left").attr("data-filter") + "')").length > 0) {
                $(this).removeClass("selected");

                var nextli = $(this).parents(".stylish-select").find(".stylish-select-left").next().find("li:containsIN('" + $(this).parents(".stylish-select").find(".stylish-select-left").attr("data-filter") + "')");
                currindex++;
                if (currindex > nextli.length - 1) {
                    currindex = 0;
                }
                nextli.eq(currindex).addClass("selected").focus();
                $(this).parents(".stylish-select").find(".stylish-select-right").text($(this).parents(".stylish-select").find(".stylish-select-left").next().find("li.selected").text());
                $(this).parents(".stylish-select").prev().val($(this).parents(".stylish-select").find(".stylish-select-left").next().find("li.selected").attr("data"));
            }

            keyinterval = setInterval(function () { clearDataFilter(); }, 300);
        } else if (e.keyCode == 27) {
            $(this).parent().hide();
            $(this).parents(".stylish-select").find(".stylish-select-left").focus();
        } else if (e.keyCode == 16) {

        } else {
            e.preventDefault();
        }
    });



    keyinterval = setInterval(function () { clearDataFilter(); }, 300);
    function clearDataFilter() {
        $(".stylish-select-left").attr("data-filter", "");
    }

    $.extend($.expr[":"], {
        "containsIN": function (elem, i, match, array) {
            return (elem.textContent || elem.innerText || "").toLowerCase().indexOf((match[3] || "").toLowerCase()) == 0;
        }
    });


    $(document).click(function () {

        $(" .listing").hide();
    });


}

/* 12-02-14  4:40 */